import React from "react"
import { Link, graphql } from "gatsby"
import { kebabCase } from "lodash"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"
import { Row } from "../components/Row"
import { Column } from "../components/Column"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "../styles/global.css"

const TagSection = styled.section`
  padding-top: 30px;
  background-color: #efefef;
  min-height: 100vh;
`

const TagWrapper = styled.div`
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  return (
    <Layout>
      <SEO title={`"${tag}"`} description={`Projects using "${tag}"`} />
      <TagSection>
        <TagWrapper>
          <Link to="/projects">
            <h2 className="margin-b-5 slim">
              {" "}
              <FontAwesomeIcon icon={"chevron-left"} /> {totalCount} PROJECT
              {totalCount === 1 ? "" : "S"} USING {` `}{" "}
              <span className="p-tag-nofs">{tag.toUpperCase()}</span>
            </h2>
          </Link>
          <Row breakpoints={[530]}>
            {edges.map(({ node }) => {
              const { title } = node.frontmatter
              const { path } = node.frontmatter
              return (
                <Column widths={[6]}>
                  <Link to={path}>
                    <Img
                      fluid={
                        node.frontmatter.featuredImage.childImageSharp.fluid
                      }
                      className="shadow"
                      objectFit="cover"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: 220,
                      }}
                    />
                    <h2 className="margin-b-5 margin-t-10">{title}</h2>
                    <p className="margin-b-5">{node.frontmatter.desc}</p>
                  </Link>
                  {node.frontmatter.tags ? (
                    <div className="flex-wrapped">
                      {node.frontmatter.tags.map(tag => (
                        <p className="margin-t-5 margin-b-5" key={tag + `tag`}>
                          <Link
                            className="p-tag"
                            to={`/tagged/${kebabCase(tag)}/`}
                          >
                            {tag.toUpperCase()}
                          </Link>
                        </p>
                      ))}
                    </div>
                  ) : null}
                </Column>
              )
            })}
          </Row>
        </TagWrapper>
      </TagSection>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            desc
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
